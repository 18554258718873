import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";

const TreatmentsPage = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulTreatments {
        treatment {
          id
          title
          offer
          text
          label
          url
        }
      }
    }
  `);
  const { treatment } = data.contentfulTreatments;

  return (
    <Layout>
      <div className="main-wrapper product-page">
        <div>
          <div className="cardRowWrapper">
            {treatment.map((el, i) => (
              <div className={`cardWrapper ${i === 1 && "hair"}`} key={el.id}>
                <h3>{el.offer}</h3>
                <div className="innerTreatmentCardWrapper">
                  <h5>{el.text}</h5>
                  <h1>{el.title}</h1>
                  <a href={el.url}>
                    {" "}
                    {el.label}
                    <svg
                      id="right-arrow"
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.06"
                      height="9.369"
                      viewBox="0 0 19.06 9.369"
                    >
                      <path
                        id="right-arrow-2"
                        data-name="right-arrow"
                        d="M13.356,13.241H.511A.511.511,0,0,0,0,13.751v2.382a.511.511,0,0,0,.511.511H13.356V18.6a1.021,1.021,0,0,0,1.743.722l3.661-3.661a1.021,1.021,0,0,0,0-1.444L15.1,10.559a1.021,1.021,0,0,0-1.743.722Z"
                        transform="translate(0 -10.258)"
                        fill="#eb4b4b"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TreatmentsPage;
